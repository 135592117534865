<template>
  <li class="list-main__item list-main__item--anztext">
    <div class="list-main__item-value flex" :class="{ 'w-100': fullWidth }" v-if="data.Lanqkey01">
      <span v-html="data.Lanqkey01" :class="{ 'w-100': fullWidth }"></span>

      <!-- Actions for parent -->
      <div v-if="data.PdfOnPath || data.InfoTooltipNumber" class="m-l-half">
        <ListItemActions :data="data"></ListItemActions>
      </div>
    </div>
    <span class="list-main__item-defaultvalue" v-if="data.DefaultValue && !fullWidth">
      <!-- For children -->
      <ul class="list w-100" v-if="getObjectSize(data.GuiNodeCh)">
        <li class="list__item" v-for="child in data.GuiNodeCh" :key="child.Xnodekey">
          <span v-html="child.Lanqkey01"></span>
        </li>
      </ul>
      <!-- No children -->
      <span v-else>{{ data.DefaultValue }}</span>
    </span>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data"></DebugItem>
  </li>
</template>

<script>
import DebugItem from '@/components/DebugItem';
import ListItemActions from '@/components/List/ListItemActions';
import mixins from '@/shared/mixins/index';

export default {
  name: 'ANZTEXT',

  mixins: [mixins],

  components: {
    DebugItem,
    ListItemActions
  },

  props: {
    data: {
      type: Object,
      required: true
    },

    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss">
.list-main__item {
  &--anztext {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;

    @media screen and (max-width: $screen-sm) {
      display: block;
    }
  }

  &-actions {
    .el-button {
      padding: 3px 0 0;
    }
  }
}
</style>
